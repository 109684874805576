<template>
	<el-card>
		<div class="d-flex flex-row flex-wrap">
			<form-item-sites :abbr="true" :multiple="false" :local="true" v-model="queryForm.site" :parent="false" @onload="q.site=queryForm.site;getList()" :clearable="false"></form-item-sites>
			<form-item label="语言:" style="width:200px;">
				<el-select v-model="queryForm.languageCode"  size="mini" clearable filterable>
					<el-option v-for="language in shortLanguages" :label="language" :value="language"></el-option>
				</el-select>
			</form-item>
			<form-item label="图片尺寸:" style="width:250px;">
				<el-select v-model="queryForm.imgSize"  size="mini" clearable filterable>
					<el-option v-for="item in picSizes" :label="item" :value="item"></el-option>
				</el-select>
			</form-item>
			<form-item>
				<el-button type="primary" size="mini" icon="el-icon-search" @click="searchEvent">查 询</el-button>
			</form-item>
			<form-item>
				<el-button type="primary" size="mini" @click="visible.add=true">添加图片模板</el-button>
			</form-item>
		</div>
		<div style="background-color:#DCDFE6;height:1px;margin:0 0 24px -20px;width:calc(100% + 40px);"></div>
		<alert v-if="multipleSelection.length > 0">
			已选择 <span class="text-primary">{{multipleSelection.length}}</span> 个。
			<el-dropdown @command="handleCommand">
			      <span class="text-primary">
			        批量操作
			      </span>
			      <el-dropdown-menu slot="dropdown">
			        <el-dropdown-item command="delete">删除</el-dropdown-item>
			      </el-dropdown-menu>
			    </el-dropdown>
		</alert>
		<gg-template-table class="mt-3 w-100" :tableData="tableData" @handleSelectionChange="handleSelectionChange" @editRow="editRow" @delRow="delRow"></gg-template-table>
		<el-pagination class="mt-3" style="text-align:right;" @size-change="handleSizeChange"
			@current-change="handleCurrentChange" :current-page.sync="page.current" :page-size="page.size"
			:page-sizes="page.sizes" layout="prev, pager, next, sizes, jumper" :total="page.total">
		</el-pagination>
		<el-dialog :visible.sync="visible.add" :title="form.id ? '更新模板' : '创建模板'" :before-close="handleClose" width="600px">
			<el-form ref="form" :model="form" :rules="rules" :hide-required-asterisk="true">
				<el-form-item-sites :abbr="true" v-model="form.site" label-width="60px" prop="site"></el-form-item-sites>
				<el-form-item label="语言:" label-width="60px" prop="languageCode">
					<el-select v-model="form.languageCode" filterable class="w-100">
						<el-option v-for="language in shortLanguages" :label="language" :value="language"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="图片类型:" label-width="80px">
					<el-select v-model="form.imgType" class="w-100">
						<el-option label="JPG" value="JPG"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="图片尺寸:" label-width="80px" prop="imgSize">
					<el-select v-model="form.imgSize" class="w-100" @change="picSizeChanged">
						<el-option v-for="item in picSizes" :label="item" :value="item"></el-option>
					</el-select>
				</el-form-item>
				<el-row :gutter="20" v-if="form.imgSize">
					<el-col :span="12">
						<el-form-item label="TOP图片:" label-width="80px" prop="topImg">
							<upload-img-button :size="form.imgSize|picInfo(picInfos, 'top')" v-model="form.topImg"></upload-img-button>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="END图片:" label-width="80px" prop="endImg">
							<upload-img-button :size="form.imgSize|picInfo(picInfos, 'end')" v-model="form.endImg"></upload-img-button>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="visible.add = false">取 消</el-button>
				<el-button type="primary" @click="doCreateOrUpdate">确 定</el-button>
			</div>
		</el-dialog>
	</el-card>
</template>

<script>
	import {
		mapActions,
		mapGetters
	} from 'vuex'
	import GgTemplateTable from '@/components/google-contents/gg-template-table.vue'
	import Config from './minixs/index.js'
	import PicSizes from './minixs/pic_sizes.js'
	const InitialForm = {
		site: '',
		languageCode: '',
		imgType:'JPG',
		imgSize: '',
		topImg: '',
		endImg: ''
	}
	export default {
		mixins:[Config, PicSizes],
		components: {
			GgTemplateTable
		},
		data() {
			return {
				queryForm: {
					site: '',
					languageCode: '',
					imgSize: ''
				},
				tableData: [],
				q: {
					site: ''
				},
				visible: {
					add: false
				},
				form: {
					...InitialForm
				},
				rules: {
					site: [{
						required: true,
						message: '站点不能为空'
					}],
					languageCode: [{
						required: true,
						message: '语言不能为空'
					}],
					imgSize: [{
						required: true,
						message: '图片尺寸不能为空'
					}],
					topImg: [{
						validator: (rule, value, callback) => {
							const picInfo = this.picInfos[this.form.imgSize]
							if ((picInfo && picInfo.top && value) || (picInfo && !picInfo.top) || !picInfo) {
								callback()
							} else {
								callback('Top图片不能为空')
							}
						}
					}],
					endImg: [{
						validator: (rule, value, callback) => {
							const picInfo = this.picInfos[this.form.imgSize]
							if ((picInfo && picInfo.end && value) || (picInfo && !picInfo.end) || !picInfo) {
								callback()
							} else {
								callback('End图片不能为空')
							}
						}
					}]
				},
				fetchOnCreated: false,
				namespace: 'google-contents-uda'
			}
		},
		mounted() {
			this.$store.dispatch('google/getShortLanguages')
		},
		computed: {
			...mapGetters({
				picInfos: 'google-contents-uda/picInfos',
				shortLanguages: 'google/shortLanguages'
			}),
			picSizes() {
				return Object.keys(this.picInfos)
			}
		},
		methods: {
			// Override
			prepareFormData() {
				return {...this.form}
			},
			fillForm(item) {
				this.form = {...item}
			},
			picSizeChanged() {
				this.form.topImg = ''
				this.form.endImg = ''
				this.$refs.form.clearValidate(['topImg', 'endImg'])
			}
		},
		watch: {
			'visible.add'(newVal, oldVal) {
				if (newVal) {
					this.$nextTick(() => {
						this.$refs.form.clearValidate()
					})
				} else {
					this.form = {
						...InitialForm
					}
				}
			}
		}
	}
</script>

<style>
</style>
