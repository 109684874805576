<template>
	<el-table height="auto" ref="multipleTable" :data="tableData" @selection-change="handleSelectionChange" @select="pinSelect">
		<el-table-column type="selection" width="55">
		</el-table-column>
		<el-table-column label="站点" prop="site" min-width="120" align="center">
		</el-table-column>
		<el-table-column label="语言" prop="languageCode" min-width="100" align="center">
		</el-table-column>
		<el-table-column label="图片尺寸" prop="imgSize" min-width="100" align="center">
		</el-table-column>
		<el-table-column label="图片类型" prop="imgType" min-width="100" align="center">
		</el-table-column>
		<el-table-column label="TOP" min-width="150" align="center">
			<template slot-scope="scope">
				<el-image
					v-if="scope.row.topImg"
				    style="width: 100px; height: 100px"
				    :src="url" 
				    :preview-src-list="[scope.row.topImg]"
					fit="contain">
				  </el-image>
				<div v-else>-</div>
			</template>
		</el-table-column>
		<el-table-column label="END" min-width="150" align="center">
			<template slot-scope="scope">
				<el-image 
					v-if="scope.row.endImg"
				    style="width: 100px; height: 100px"
				    :src="url" 
				    :preview-src-list="[scope.row.endImg]"
					fit="contain">
				  </el-image>
				<div v-else>-</div>
			</template>
		</el-table-column>
		<el-table-column label="操作" min-width="100" align="center">
			<template slot-scope="scope">
				<el-button type="text" @click="$emit('editRow', scope.row)">编辑</el-button>
				<el-button type="text" class="text-danger" @click="$emit('delRow', scope.row)">删除</el-button>
			</template>
		</el-table-column>
	</el-table>
</template>

<script>
	import MultipleTableSelection from '@/common/mixins/multiple-table-selection.js'
	import {mapGetters} from 'vuex'
	export default {
		mixins: [MultipleTableSelection],
		props: {
			tableData: {
				type: Array,
				default: []
			}
		}
	}
</script>

<style>
</style>
