export default {
	filters: {
		picInfo(key, picInfos, location = 'top') {
			try {
				const sizes = picInfos[key][location].match(/\d+/g)
				return {
					width: Number(sizes[0]),
					height: Number(sizes[1])
				}
			} catch (e) {
				console.error(e)
				return null
			}
		}
	}
}
